console.log('main.js');

document.addEventListener('DOMContentLoaded', function () {
  // Открытие и закрытие меню в шапке
  const menuBtn = document.getElementById('menu-btn');
  const primaryMenu = document.getElementById('primary-menu');

  menuBtn.addEventListener('click', function () {
    primaryMenu.classList.toggle('hidden');
    menuBtn.classList.toggle('menu__btn-close');

    const isExpanded = menuBtn.getAttribute('aria-expanded') === 'true';
    menuBtn.setAttribute('aria-expanded', !isExpanded);
  });

  // Открытие и закрытие меню в футере
  const footerNavTitles = document.querySelectorAll('.drop-down');

  footerNavTitles.forEach((title) => {
    title.addEventListener('click', function () {
      const ul = this.nextElementSibling;
      const svg = this.querySelector('svg');

      if (ul) {
        const isExpanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', !isExpanded);

        ul.classList.toggle('hidden');
        ul.classList.toggle('grid');

        if (svg) {
          svg.style.transform = isExpanded ? 'rotate(0deg)' : 'rotate(180deg)';
        }
      }
    });
  });

  // Обработка нажатия клавиши Enter
  footerNavTitles.forEach((title) => {
    title.addEventListener('keydown', function (event) {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.click();
      }
    });
  });
});

document.querySelectorAll('.language-filter-item').forEach((button) => {
  button.addEventListener('click', function () {
    const isPressed = this.getAttribute('aria-pressed') === 'true';
    const newState = !isPressed;

    this.setAttribute('aria-pressed', newState);
    this.classList.toggle('bg-custom-lavender', newState);

    // Показываем или скрываем иконку закрытия
    const closeIcon = this.querySelector('svg');
    if (closeIcon) {
      closeIcon.classList.toggle('hidden', !newState);
    }

    // Здесь добавьте логику фильтрации
    const language = this.dataset.language;
    console.log(`Фильтр по языку ${language} ${newState ? 'включен' : 'выключен'}`);
  });
});

// Modal

document.addEventListener('DOMContentLoaded', (event) => {
  const openButtons = document.querySelectorAll('.open-modal');
  const closeButtons = document.querySelectorAll('.close-modal');
  const modals = document.querySelectorAll('.modal');

  function openModal(modal) {
    modal.classList.remove('hidden');
    const closeButton = modal.querySelector('.close-modal');
    closeButton.focus();
  }

  function closeModal(modal) {
    modal.classList.add('hidden');
    const videoFrame = modal.querySelector('.youtube-video');
    if (videoFrame) {
      videoFrame.src = videoFrame.src; // Сброс видео при закрытии
    }
    const openButton = document.querySelector(`[data-modal-target="${modal.id}"]`);
    if (openButton) {
      openButton.focus();
    }
  }

  openButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const modal = document.getElementById(button.dataset.modalTarget);
      openModal(modal);
    });
  });

  closeButtons.forEach((button) => {
    button.addEventListener('click', () => {
      const modal = button.closest('.modal');
      closeModal(modal);
    });
  });

  // Закрытие по клавише Escape
  document.addEventListener('keydown', function (event) {
    if (event.key === 'Escape') {
      modals.forEach((modal) => {
        if (!modal.classList.contains('hidden')) {
          closeModal(modal);
        }
      });
    }
  });

  // Закрытие при клике вне модального окна
  modals.forEach((modal) => {
    modal.addEventListener('click', function (event) {
      if (event.target === modal) {
        closeModal(modal);
      }
    });
  });

  // Trap focus внутри модального окна
  modals.forEach((modal) => {
    modal.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        const focusableElements = modal.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];

        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            lastElement.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastElement) {
            firstElement.focus();
            e.preventDefault();
          }
        }
      }
    });
  });
});
